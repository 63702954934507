<template>

  <div class="row" ref="notificationForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-8 col-sm-12">

                <ValidationProvider
                  vid="notificationHeader"
                  rules="required"
                  name="Notification Header"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Notification Header *"
                            name="notificationHeader"
                            fou
                            v-model="formData.notification_header">
                  </fg-input>
                </ValidationProvider>
                <h5>Icon</h5>
                <prime-uploader
                  class="mb-4"
                  :preview-width="'200px'"
                  :preview-height="'200px'"
                  :elementNum="1"
                  v-model="formData.small_icon"
                >
                </prime-uploader>
                <ValidationProvider
                  vid="notificationBody"
                  rules="required"
                  name="notification Body"

                  v-slot="{ passed, failed,errors }">
                  <editor
                    v-model="formData.notification_body"
                    :api-key="editorKey"
                    :init='{
                    height: 400,
                    paste_data_images: true,
                    plugins: [
                    "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                     "searchreplace wordcount visualblocks visualchars code fullscreen",
                    "insertdatetime media nonbreaking save table contextmenu directionality",
                    "emoticons template paste textcolor colorpicker textpattern"
                    ],
                    toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                    toolbar2: "print preview media | forecolor backcolor emoticons",
                    image_advtab: true,
                  }'
                  />
                </ValidationProvider>

              </div>


            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/notifications/'+ formData.lang)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Tooltip} from 'element-ui';
import FormGroupTextArea, {ImageUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {PrimeUploader} from '@/components'


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Tooltip.name]: Tooltip,
    PrimeUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        notification_body: "",
        notification_header: "",
        lang:'',
        small_icon:'',
      },
    };
  },


  created(){
    const path = window.location.href;
    this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
  },

  methods: {

    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      request = this.axios.post("notifications/create", this.formData);
      successMessage = "Notification Added Successfully";

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/notifications/"+ this.formData.lang);
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          this.$notify({
            message: error.response.data.message,
            timeout: 2000,
            type: 'danger'
          })
        }
      }).finally(() => {
        this.submitting = false;
      })
    },

  }
}
</script>
