var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"notificationForm",staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12 col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 col-sm-12"},[_c('ValidationProvider',{attrs:{"vid":"notificationHeader","rules":"required","name":"Notification Header"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Notification Header *","name":"notificationHeader","fou":""},model:{value:(_vm.formData.notification_header),callback:function ($$v) {_vm.$set(_vm.formData, "notification_header", $$v)},expression:"formData.notification_header"}})]}}],null,true)}),_c('h5',[_vm._v("Icon")]),_c('prime-uploader',{staticClass:"mb-4",attrs:{"preview-width":'200px',"preview-height":'200px',"elementNum":1},model:{value:(_vm.formData.small_icon),callback:function ($$v) {_vm.$set(_vm.formData, "small_icon", $$v)},expression:"formData.small_icon"}}),_c('ValidationProvider',{attrs:{"vid":"notificationBody","rules":"required","name":"notification Body"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('editor',{attrs:{"api-key":_vm.editorKey,"init":{
                  height: 400,
                  paste_data_images: true,
                  plugins: [
                  "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                   "searchreplace wordcount visualblocks visualchars code fullscreen",
                  "insertdatetime media nonbreaking save table contextmenu directionality",
                  "emoticons template paste textcolor colorpicker textpattern"
                  ],
                  toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                  toolbar2: "print preview media | forecolor backcolor emoticons",
                  image_advtab: true,
                }},model:{value:(_vm.formData.notification_body),callback:function ($$v) {_vm.$set(_vm.formData, "notification_body", $$v)},expression:"formData.notification_body"}})]}}],null,true)})],1)])]),_c('div',{staticClass:"card-footer text-right"},[_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v("Submit ")]),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/notifications/'+ _vm.formData.lang)}}},[_vm._v("Cancel ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }