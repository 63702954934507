<template>
  <div class="row" ref="pollList">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Poll List</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/polls/create'])"
                       class="btn btn-info btn-wd"
                       :to="'/polls/create/'+lang">
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>

        <div>
          <general-data-table
            ref="table"
            :lang="lang"
            :api-url="'polls/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label" >

              <template slot-scope="scope">
 
                <div v-if="column.value === 'answers'">
                  <table class="table table-borderless" >
                    <tbody >
                      <tr v-for="answer in scope.row[column.value]" >
                        <td style="width:50%">{{ answer.text }}</td>
                        <td style="width:100%"><span class="badge badge-info">{{ parseInt(answer.percentage) }} %</span></td>
                      </tr>
                    </tbody>
                  </table>
<!--                  <span v-for="answer in scope.row[column.value]">-->
<!--                  <span>{{ answer.text }}</span>-->
<!--                  <span class="badge badge-info ml-5">{{ parseInt(answer.percentage) }} %</span>-->
<!--                  <br>-->
<!--                  </span>-->
                </div>

                <span v-else>{{ scope.row[column.value] }}</span>

              </template>

            </el-table-column>
            <el-table-column
                    :min-width="120"
                    align="center"
                    label="Actions">
              <template slot-scope="scope">
                <template>
                  <router-link v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/polls/update']) ||
                                     $store.getters['auth/checkAccessOwner']('restricted_own/'+lang+'/polls/update', scope.row.creator_id)"
                               v-tooltip.top-center="'Edit'"
                               class="btn-warning btn-simple btn-link"
                               :to="'/polls/edit/'+scope.row.id+'/'+lang">
                    <i class="fa fa-edit"></i>
                  </router-link>
                  <a v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/polls/update-percentage']) ||
                           $store.getters['auth/checkAccessOwner']('restricted_own/'+lang+'/polls/update-percentage', scope.row.creator_id)"
                     v-tooltip.top-center="'Edit Vote Percentage'"
                     class="btn-warning btn-simple btn-link"
                     @click="openEditPercentageModal(scope.row.id)">
                    <i class="fa fa-percent"></i></a>
                  <router-link  v-if="$store.getters['auth/haveOneOfPermissions'](['polls_'+lang+'_logs_view'])"
                                v-tooltip.top-center="'Log'"
                                class="btn-info btn-simple btn-link"
                                :to="'/logs/polls_' + lang+'_'+scope.row.id"><i class="fa fa-eye"></i></router-link>

                  <a v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/polls/delete']) ||
                           $store.getters['auth/checkAccessOwner']('restricted_own/'+lang+'/polls/delete', scope.row.creator_id)"
                     v-tooltip.top-center="'Delete'"
                     class="btn-danger btn-simple btn-link"
                     @click="openDeleteModal(scope.row.id)">
                    <i class="fa fa-times"></i></a>
                </template>


              </template>
            </el-table-column>

          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this Poll?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
      <edit-percentage-modal :visible="editPercentageModalVisibility"
                    :poll="poll"
                    :key="percentageModalKey"
                    @close="closeEditPercentageModal()"
                    @updatePercentage="updatePercentage">
      </edit-percentage-modal>

    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import EditPercentageModal from "../../components/Poll/EditPercentageModal";


export default {
  components: {
    EditPercentageModal,
    NLPagination,
    DeleteModal,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
  },

  data() {

    return {
      tableColumns: [
        {label: 'Question', value: 'question', minWidth: '200', align: 'center'},
        {label: 'Answers', value: 'answers', minWidth: '200', align: 'center'},
        {label: 'Display inside Pages', value: 'show_inside_pages', minWidth: '100', align: 'center'},
      ],

      deleteModalVisibility: false,
      toDeleteId: undefined,
      lang: 'ar',
      editPercentageModalVisibility: false,
      poll: {},
      loader: {},
      percentageModalKey: 0,
    }

  },
  created(){
      const path = window.location.href;
      this.lang = path.substring(path.lastIndexOf('/') + 1)
  },
  methods: {
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
          'lang': this.lang
      }
      this.axios.delete("polls/delete", {headers: {}, data}).then((response) => {

        this.$notify({
          message: "Poll deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
      openEditPercentageModal(id) {
      this.loader = this.$loading.show({
          container: this.$refs.pollList
      });
      this.getPoll(id);
    },

    closeEditPercentageModal() {
      this.editPercentageModalVisibility = false;
    },
    getPoll(id) {
      let data = {
          id: id,
          lang: this.lang
      };
        this.axios.post("polls/get", data).then((response) => {
            this.poll = response.data;
            this.editPercentageModalVisibility = true;
            this.percentageModalKey++;
            this.loader.hide();
        }).catch((error) => {
            if (error.response.status === 404) {
                this.$notify({
                    message: "Poll Not Found",
                    timeout: 2000,
                    type: 'danger'
                });
            } else {
                console.error(error);
            }
        });
    },
    updatePercentage(poll) {
      let data = {
          'id': poll.id,
          'answers': poll.back_answers,
          'lang': this.lang
      }
      this.axios.post("polls/update-percentage", data).then((response) => {

        this.$notify({
          message: "Poll Updated successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeEditPercentageModal();
      this.poll = undefined;
    },
  },
}
</script>
<style>
.table, tbody, tr, td, td:last-child{
  border: none;
}
</style>
